import React, { useState } from 'react';
import { useLocation } from "@reach/router";
import { Checkbox } from '../../Checkbox/Checkbox';
import { Input } from '../../Input/Input';
import styles from "./InvestmentForm.module.scss";
import { getApiUrl } from '../../../constants';
import { navigate } from "gatsby"
import { Form } from '../../Form/Form';
import LeftPlaneIllustration from "../../../assets/airplanes/red.svg";
import RightPlaneIllustration from "../../../assets/airplanes/blue.svg";

interface InvestmentFormProps {

}

interface MyWindow extends Window {
	fbq: any;
	ga: any;
}

declare let window: MyWindow

if (process.env.NODE_ENV === "development") window.fbq = function () { console.log('fake fbq fired with', arguments) };
if (process.env.NODE_ENV === "development") window.ga = function () { console.log('fake ga fired with', arguments) };

const sendEvent = (eventName: string) => {
	window.fbq && window.fbq('track', eventName);
	window.ga && window.ga('send', eventName);
}
export const InvestmentForm: React.FC<InvestmentFormProps> = ({ }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [agreementError, setAgreementError] = useState(null);
	const location = useLocation();

	const params = new URLSearchParams(location.search);

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		const { name, email, phone, agreement } = e.target as any;

		setAgreementError(!agreement.checked);
		if (!agreement.checked) return;

		const body = {
			firstName: name.value,
			email: email.value,
			phone: phone.value,
			source: params.get('utm_source'),
			campaign: params.get('utm_campaign'),
			term: params.get('utm_term'),
			content: params.get('utm_content'),
			medium: params.get('utm_medium'),
		}

		setIsLoading(true);

		try {
			await fetch(`${getApiUrl()}/newsletter/investment`, { method: "POST", body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } });

			sendEvent("investmentNewsletter")

			setIsLoading(false);

			navigate('/lp/investment/success');
		} catch (e) {
			setError(e);

			setIsLoading(false);
		}
	}

	return (
		<section style={{ position: 'relative' }}>
			<LeftPlaneIllustration className={styles.leftIllustration} />
			<RightPlaneIllustration className={styles.rightIllustration} />
			<div className={`container ${styles.container}`}>
				<h2>Zapisz się na webinar wypełniając ten formularz</h2>
				<Form onSubmit={handleSubmit} isLoading={isLoading} className={styles.form}>
					<Input
						name="name"
						placeholder='Imię'
						required
						gutterBottom
					/>
					<Input
						name="email"
						placeholder='E-mail'
						required
						type="email"
						gutterBottom
					/>
					<Input
						type="tel"
						name="phone"
						placeholder='Numer telefonu'
					/>
					<div className={styles.tos}>
						<Checkbox
							error={agreementError}
							name="agreement"
							label="Chcę zapisać się do newslettera, a co za tym idzie wyrażam zgodę na otrzymanie na mój adres e-mail i numer telefonu informacji o szczegółach działalności firmy Wizyter PSA oraz o przypomnieniu kiedy i gdzie ma się odbyć webinar. Wiem, że w każdej chwili mogę odwołać zgodę."
						/>
					</div>
					{
						!isLoading && error && <p className={styles.error}>Wystąpił błąd podczas zapisywania się na webinar<br />Sprawdź podane dane i spróbuj jeszcze raz</p>
					}
					<button type="submit" className={`button ${styles.button}`}>
						Zapisz się
					</button>
				</Form>
			</div>
		</section>
	)
}