import React from 'react';
import styles from "./InvestmentHero.module.scss";
import HeroIllustration from "../../../assets/investmentHero.svg";
import Line from "../../../assets/lines/line1.svg";
import LineSm from "../../../assets/lines/line1-sm.svg";

interface InvestmentHeroProps {

}

export const InvestmentHero: React.FC<InvestmentHeroProps> = ({ }) => {
	return (
		<section className="container">
			<Line className={styles.line} />
			<LineSm className={styles.lineSm} />
			<div className={styles.container}>
				<h1 className="highlight">Szukasz ciekawej możliwości ulokowania pieniędzy?</h1>
				<HeroIllustration className={styles.illustration} />
				<p className={styles.text}>Zapisz się na bezpłatny webinar, na temat zakupu akcji firmy Wizyter PSA.</p>
				<p className={`highlight ${styles.date}`}>1 MARCA 2022 | WTOREK | 18:00</p>
			</div>
		</section>
	)
}