import React from 'react';
import { Helmet } from "react-helmet";
import { Footer } from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header/Header";
import { InvestmentAdvantages } from '../../../components/Investment/InvestmentAdvantages/InvestmentAdvantages';
import { InvestmentDate } from '../../../components/Investment/InvestmentDate/InvestmentDate';
import { InvestmentForm } from '../../../components/Investment/InvestmentForm/InvestmentForm';
import { InvestmentHero } from '../../../components/Investment/InvestmentHero/InvestmentHero';
import { InvestmentReview } from '../../../components/Investment/InvestmentReview/InvestmentReview';
import { useDefaultFooterData } from '../../../hooks/useDefaultFooterData';

interface InvestmentProps {

}

const Investment: React.FC<InvestmentProps> = ({ }) => {
	const { socials } = useDefaultFooterData();

	return (
		<>
			<Helmet>
				<title>Wizyter.pl</title>
			</Helmet>
			<div className="main-font-headers">
				<Header withRegisterButton={false} />
				<InvestmentHero />
				<InvestmentReview />
				<InvestmentDate />
				<InvestmentForm />
				<InvestmentAdvantages />
				<Footer socials={socials} />
			</div>
		</>
	)
}

export default Investment;