import React from 'react';
import styles from "./InvestmentReview.module.scss";
import Line from "../../../assets/lines/line2.svg";
import LineSm from "../../../assets/lines/line2-sm.svg";

interface InvestmentReviewProps {

}

export const InvestmentReview: React.FC<InvestmentReviewProps> = ({ }) => {
	return (
		<section style={{ position: 'relative' }}>
			<Line className={styles.line} />
			<LineSm className={styles.lineSm} />
			<div className={styles.container}>
				<div className="container">
					<ul className={styles.list}>
						<li>
							<p>Możesz zakupić akcje już od <span className="highlight">1 tys. zł</span></p>
						</li>
						<li>
							<p>Pokażemy jaki jest nasz <span className="highlight">model biznesowy</span></p>
						</li>
						<li>
							<p>Planowana stopa zwrotu z inwestycji to <span className="highlight">30% na rok</span></p>
						</li>
						<li>
							<p>Omówimy <span className="highlight">strategie wyjścia</span> z inwestycji</p>
						</li>
						<li>
							<p>Opowiemy jak działa aplikacja Wizyter i jakie mamy <span className="highlight">plany rozwoju</span></p>
						</li>
						<li>
							<p>	Będziesz mógł zadać <span className="highlight">dodatkowe pytania</span> na czacie podczas webinaru</p>
						</li>
					</ul>
				</div>
			</div>
		</section>
	)
}