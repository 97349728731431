import React from 'react';
import styles from "./InvestmentDate.module.scss";

interface InvestmentDateProps {

}

export const InvestmentDate: React.FC<InvestmentDateProps> = ({ }) => {
	return (
		<div className={styles.container}>
			<h3>
				1 Marca 2022 | Wtorek | 18:00
			</h3>
			<div className={styles.bookmarkContainer}>
				<div className={styles.bookmark} />
			</div>
		</div>
	)
}