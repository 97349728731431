import React from 'react';
import styles from "./Checkbox.module.scss";
import { InputProps } from "../../types"

interface CheckboxProps {
	label: string;
	error?: boolean;
}

export const Checkbox: React.FC<CheckboxProps & Omit<InputProps, 'type'>> = ({ label, error, ...props }) => {

	let className = styles.container;

	if (error) { className += ` ${styles.error}` };

	return (
		<label className={className}>
			<div className={styles.inputContainer}>
				<input type="checkbox" {...props} />
			</div>
			{label}
		</label>
	)
}