import React from 'react';
import FieldIllustration from "../../../assets/investmentAdvantages/field.svg";
import PriceIllustration from "../../../assets/investmentAdvantages/price.svg";
import ReturnIllustration from "../../../assets/investmentAdvantages/return.svg";
import styles from "./InvestmentAdvantages.module.scss";
import Line from "../../../assets/lines/line3.svg";
import LineSm from "../../../assets/lines/line3-sm.svg";

interface InvestmentAdvantagesProps {

}

export const InvestmentAdvantages: React.FC<InvestmentAdvantagesProps> = ({ }) => {
	return (
		<section style={{ position: 'relative' }}>
			<Line className={styles.line} />
			<LineSm className={styles.lineSm} />
			<div className={`container ${styles.container}`}>
				<div className={styles.card}>
					<PriceIllustration />
					<p className="highlight">Inwestycja od 1 tys. zł</p>
				</div>
				<div className={styles.card}>
					<FieldIllustration />
					<p className="highlight">Spółka z branży<br />nowych technologii</p>
				</div>
				<div className={styles.card}>
					<ReturnIllustration />
					<p className="highlight">Planowany zwrot z inwestycji<br />30% na rok</p>
				</div>
			</div>
		</section>
	)
}